import React from 'react'
import styled from 'styled-components'

export interface TagButtonProps {
  name: string
  onClick?: () => void
  active?: boolean
}
export default function TagButton({ name, onClick, active }: TagButtonProps) {
  return (
    <Button type='button' onClick={onClick} className={`${active && 'active'}`}>
      {name}
    </Button>
  )
}

const { Button } = {
  Button: styled.button`
    background: transparent;
    border: none;
    font-size: 16px;
    line-height: 24px;
    height: 40px;
    padding: 8px 16px;
    border-radius: 32px;
    color: ${props => props.theme.colors.black};
    cursor: pointer;

    &:hover {
      background: ${props => props.theme.colors.gray[0]};
    }

    &.active {
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.black};
    }
  `
}
