import moment from 'moment'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

type CountdownFooterCardData = {
  days: string
  hours: string | number
  minutes: string
  seconds: string
}

type CountdownFooterCardProps = {
  cutoff: number
}

// eslint-disable-next-line no-shadow
export enum CountdownDisplayType {
  liveAuction = 'LIVE_AUCTION',
  timeToUnLock = 'TIME_TO_UNLOCK'
}
export function CountdownFooterCard({ cutoff }: CountdownFooterCardProps) {
  const [countdownData, setCountdownData] = useState<CountdownFooterCardData | undefined>(undefined)
  const [finish, setFinish] = useState<boolean>(false)
  useEffect(() => {
    const finishAuctionDate = moment.unix(cutoff)
    const launchInterval = setInterval(() => updateCountdown(), 1000)
    const updateCountdown = () => {
      const currentTime = moment.utc()
      const diff = moment(finishAuctionDate.diff(currentTime)).utc()
      const diffDays = finishAuctionDate.diff(currentTime, 'days')
      if (currentTime.isSameOrAfter(finishAuctionDate)) {
        setCountdownData({
          days: '00',
          hours: '00',
          minutes: '00',
          seconds: '00'
        })

        clearInterval(launchInterval)
        setFinish(true)
        return
      }

      setCountdownData({
        days: diffDays > 9 ? diffDays.toString(10) : `0${diffDays.toString(10)}`,
        hours: diff.format('HH'),
        minutes: diff.format('mm'),
        seconds: diff.format('ss')
      })
    }
  }, [cutoff])
  return (
    <>
      {!finish && (
        <S.Counter>
          <div>
            <span>{countdownData?.days}d</span>
          </div>
          <div>
            <span>{countdownData?.hours}h</span>
          </div>
          <div>
            <span>{countdownData?.minutes}m</span>
          </div>
          <div>
            <span>{countdownData?.seconds}s</span>
          </div>
        </S.Counter>
      )}
    </>
  )
}

const S = {
  Counter: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    div {
      span {
        font-weight: 500;
        font-size: 14px;
        color: ${props => props.theme.colors.black};
      }
    }
  `
}
