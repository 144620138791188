import { useQuery } from '@apollo/client'
import {
  MarketplaceItemsQueryData,
  MarketplaceItemsQueryVars,
  MARKETPLACE_ITEMS
} from '@graphql/nftfy/backend/marketplace/marketplace-item/MarketplaceItems'
import { useEffect, useState } from 'react'
import { notifyError } from '@services/NotificationService'
import { code } from '@messages'
import { backendV2 } from '../graphql/ClientGraphql'
import { MarketplaceFilter } from '../graphql/variables/MarketplaceVariable'
import { useGlobalConfig } from './ConfigHook'

export const useMarketplaceItems = (chainId: number, marketplaceFilters: MarketplaceFilter) => {
  const { paginationLimit } = useGlobalConfig()
  const [offset, setOffset] = useState<number>(0)
  const [hasMore, setHasMore] = useState<boolean>(true)

  const { loading, data, fetchMore, error } = useQuery<MarketplaceItemsQueryData, MarketplaceItemsQueryVars>(MARKETPLACE_ITEMS, {
    client: backendV2,
    variables: {
      chainId,
      limit: paginationLimit,
      filterBy: marketplaceFilters.type,
      offset,
      orderBy: marketplaceFilters.sortingField,
      orderDirection: marketplaceFilters.sortingDirection
    }
  })

  const loadMore = async () => {
    if (hasMore && !loading) {
      const isLoaded = (data?.marketplaceItems.length || 0) - paginationLimit >= paginationLimit
      const newOffset = offset + paginationLimit
      const { data: nextPageData, error: nextPageError } = await fetchMore({
        variables: {
          offset: isLoaded ? data?.marketplaceItems.length || 0 : newOffset
        }
      })

      if (nextPageError) {
        notifyError(code[5015])
        return
      }

      setOffset(newOffset)
      setHasMore(nextPageData.marketplaceItems.length === paginationLimit)
    }

    if (!data?.marketplaceItems.length) {
      setHasMore(false)
    }
  }

  useEffect(() => {
    setOffset(0)
    setHasMore(true)
  }, [chainId, marketplaceFilters])

  if (error) {
    notifyError(code[5015])
    return { marketplaceItems: [], loading, loadMore, hasMore }
  }

  return { marketplaceItems: data?.marketplaceItems || [], loading, loadMore, hasMore }
}
