import { useReactiveVar } from '@apollo/client'
import { useEffect } from 'react'
import { marketplaceFiltersVar } from '../../../graphql/variables/MarketplaceVariable'
import { useLocalStorage } from '../../../hooks/LocalStorageHooks'
import { useRouteParams, useRouteSort } from '../../../hooks/RouteHooks'
import { MarketplaceItemOrderBy } from '../../../types/MarketplaceTypes'
import { OrderDirection } from '../../../types/UtilTypes'
import SelectFilter from '../../design-system/SelectFilter'

export default function SortFilter() {
  const marketplaceFilters = useReactiveVar(marketplaceFiltersVar)
  const { setItem } = useRouteParams()
  const handleLocalStorage = useLocalStorage()

  const sorts = [
    {
      id: 'timestamp-desc',
      name: 'Recent',
      action() {
        setOrder('timestamp-desc')
      }
    },
    {
      id: 'timestamp-asc',
      name: 'Old',
      action() {
        setOrder('timestamp-asc')
      }
    },
    {
      id: 'name-asc',
      name: 'A-Z',

      action() {
        setOrder('name-asc')
      }
    },
    {
      id: 'name-desc',
      name: 'Z-A',
      action() {
        setOrder('name-desc')
      }
    }
  ]
  const routeSort = useRouteSort('sort')

  const setOrder = (order: string) => {
    const orderingParts = order.toString().split('-')
    marketplaceFiltersVar({
      ...marketplaceFilters,
      sortingDirection: orderingParts[1] as OrderDirection,
      sortingField: orderingParts[0] as MarketplaceItemOrderBy
    })
    setItem('sort', order)
    handleLocalStorage.setItem('sort', order)
  }

  useEffect(() => {
    handleLocalStorage.setItem('sort', String(routeSort))
  }, [handleLocalStorage, routeSort])

  useEffect(() => {
    const orderingParts = routeSort.toString().split('-')
    marketplaceFiltersVar({
      ...marketplaceFiltersVar(),
      sortingDirection: orderingParts[1] as OrderDirection,
      sortingField: orderingParts[0] as MarketplaceItemOrderBy
    })
  }, [routeSort])

  return <SelectFilter width={120} data={sorts} value={routeSort || 'timestamp-desc'} />
}
