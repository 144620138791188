import styled from 'styled-components'
import TagButton from '../shared/buttons/TagButton'
import NetworkFilter from '../shared/filters/NetworkFilter'
import SortFilter from '../shared/filters/SortFilter'
import MarketplaceTypeFilter from './MarketplaceTypeFilter'

interface MarketplaceHeaderProps {
  totalItems?: number
}

export default function MarketplaceFilters({ totalItems }: MarketplaceHeaderProps) {
  return (
    <Section>
      <div>
        <Title>Explore</Title>
        {totalItems && <SubTitle>{`${totalItems} items`}</SubTitle>}
      </div>
      <div>
        <NetworkFilter />
      </div>
      <div>
        <TagButton name='Items' active />
      </div>
      <div>
        <MarketplaceTypeFilter />
        <SortFilter />
      </div>
    </Section>
  )
}
const { Section, Title, SubTitle } = {
  Section: styled.section`
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 20px;
    margin-bottom: 20px;

    > div:nth-child(2) {
      margin-top: 8px;
    }

    > div:nth-child(3) {
      display: none;
    }

    > div:nth-child(4) {
      display: grid;
      grid-template-columns: minmax(0, 1fr) 120px;

      gap: 20px;
    }

    @media (min-width: ${props => props.theme.viewport.desktop}) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 60px 40px;
      gap: 20px;
      margin-bottom: 40px;

      > div:nth-child(odd) {
        justify-content: left;
      }

      > div:nth-child(even) {
        justify-content: right;
      }

      > div:nth-child(3) {
        display: block;
      }

      > div:nth-child(4) {
        display: grid;
        grid-template-columns: auto auto;
        gap: 20px;
      }
    }
  `,
  Title: styled.h1`
    font-size: 36px;
    line-height: 48px;
    font-weight: 300;
    color: ${props => props.theme.colors.black};
  `,
  SubTitle: styled.p`
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: ${props => props.theme.colors.gray[3]};
  `
}
