import { useReactiveVar } from '@apollo/client'
import CardGrid from '@components/design-system/CardGrid'
import CardLoader from '@components/design-system/CardLoader'
import MarketplaceCard from '@components/shared/cards/MarketplaceCard'
import EmptyState from '@components/shared/EmptyState'
import { DefaultPageTemplate } from '@components/shared/templates/DefaultPageTemplate'
import { blacklist, SupportedNetworks } from '@config'
import { marketplaceFiltersVar } from '@graphql/variables/MarketplaceVariable'
import { useMarketplaceItems } from '@hooks/MarketplaceHooks'
import { GetServerSideProps } from 'next'

import { MarketplaceItem } from '@appTypes/MarketplaceTypes'
import MarketplaceFilters from '../../../components/marketplace/MarketplaceFilters'
import MarketplaceHeader from '../../../components/marketplace/MarketplaceHeader'

interface MarketplacePageProps {
  chainId: number
}

export default function MarketplacePage({ chainId }: MarketplacePageProps) {
  const marketplaceFilters = useReactiveVar(marketplaceFiltersVar)
  const marketplaceItems = useMarketplaceItems(chainId, marketplaceFilters)

  return (
    <DefaultPageTemplate header={<MarketplaceHeader chainId={chainId} />}>
      <MarketplaceFilters />
      {!marketplaceItems.loading && !marketplaceItems.marketplaceItems?.length && !marketplaceItems.hasMore && <EmptyState />}
      <CardGrid
        hasMore={marketplaceItems.hasMore}
        loader={<CardLoader />}
        loadMore={marketplaceItems.loadMore}
        data={marketplaceItems.marketplaceItems.map(
          (nftItem: MarketplaceItem) =>
            !blacklist.includes(`${nftItem.chainId}_${nftItem.id}`) && <MarketplaceCard erc20={nftItem} key={nftItem.id} />
        )}
      />
    </DefaultPageTemplate>
  )
}

export const getServerSideProps: GetServerSideProps = async context => {
  const chainId = context.params?.chainId || SupportedNetworks.ethereum

  return {
    props: {
      chainId: Number(chainId)
    }
  }
}
