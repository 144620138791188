import nftfyWhite from '@assets/nftfy/nftfy-white.svg'
import { productMenu } from '../../services/ProductMenuService'
import Header from '../design-system/header/Header'

interface MarketplaceHeaderProps {
  chainId: number
}

export default function MarketplaceHeader({ chainId }: MarketplaceHeaderProps) {
  const logo = {
    image: nftfyWhite.src,
    name: 'Marketplace',
    href: `/marketplace/${chainId}`
  }

  const menu = [
    {
      name: 'Explore',
      href: `/marketplace/${chainId}`,
      activeHref: ['/marketplace/[chainId]']
    },
    {
      name: 'Fractionalize NFTs',
      href: `/marketplace/${chainId}/create`,
      activeHref: ['/marketplace/[chainId]/create']
    }
  ]

  return <Header logo={logo} menu={menu} productMenu={productMenu(chainId)} />
}
