import { useReactiveVar } from '@apollo/client'
import { MarketplaceItemFilter } from '@appTypes/MarketplaceTypes'
import SelectFilter from '@components/design-system/SelectFilter'
import { SaleTypesIcons } from '@components/shared/Icon'
import { marketplaceFiltersVar } from '@graphql/variables/MarketplaceVariable'
import { useLocalStorage } from '@hooks/LocalStorageHooks'
import { useRouteMarketplaceSaleType, useRouteParams } from '@hooks/RouteHooks'
import { useWindowSize } from '@hooks/UtilsHooks'
import { useEffect } from 'react'
import { useTheme } from 'styled-components'

export default function MarketplaceTypeFilter() {
  const marketplaceFilters = useReactiveVar(marketplaceFiltersVar)
  const { setItem } = useRouteParams()
  const handleLocalStorage = useLocalStorage()
  const { width } = useWindowSize()
  const theme = useTheme()

  const saleTypes = [
    {
      id: MarketplaceItemFilter.ALL,
      name: 'All',
      image: SaleTypesIcons.all,
      action() {
        setSaleType(MarketplaceItemFilter.ALL)
      }
    },
    {
      id: MarketplaceItemFilter.START_AUCTION,
      name: 'Start Auction',
      image: SaleTypesIcons.startAuction,
      action() {
        setSaleType(MarketplaceItemFilter.START_AUCTION)
      }
    },
    {
      id: MarketplaceItemFilter.LIVE_AUCTION,
      name: 'Live Auction',
      image: SaleTypesIcons.liveAuction,
      action() {
        setSaleType(MarketplaceItemFilter.LIVE_AUCTION)
      }
    },
    {
      id: MarketplaceItemFilter.BUY_NOW,
      name: 'Buy Now',
      image: SaleTypesIcons.buyNow,
      action() {
        setSaleType(MarketplaceItemFilter.BUY_NOW)
      }
    },
    {
      id: MarketplaceItemFilter.SOLD,
      name: 'Recently Sold',
      image: SaleTypesIcons.recentlySold,
      action() {
        setSaleType(MarketplaceItemFilter.SOLD)
      }
    }
  ]
  const setSaleType = (value: MarketplaceItemFilter) => {
    marketplaceFiltersVar({ ...marketplaceFilters, type: value })
    setItem('saleType', value)
    handleLocalStorage.setItem('saleType', value)
  }

  const routeSaleType = useRouteMarketplaceSaleType()

  useEffect(() => {
    handleLocalStorage.setItem('saleType', String(routeSaleType))
  }, [handleLocalStorage, routeSaleType])

  useEffect(() => {
    marketplaceFiltersVar({ ...marketplaceFiltersVar(), type: routeSaleType })
  }, [routeSaleType])

  return <SelectFilter fullWidth={!!width && width < Number(theme.viewport.desktop)} width={210} data={saleTypes} value={routeSaleType} />
}
