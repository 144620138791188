import { makeVar } from '@apollo/client'
import { MarketplaceItem, MarketplaceItemFilter, MarketplaceItemOrderBy, UpdaterOrderModal } from '@appTypes/MarketplaceTypes'
import { OrderDirection } from '@appTypes/UtilTypes'
import { AssetERC20 } from '@appTypes/WalletTypes'

export const buyModalVar = makeVar<
  | {
      type: 'nft' | 'shares'
      item: MarketplaceItem
    }
  | undefined
>(undefined)

export const buyModalLiquidityVar = makeVar<boolean>(false)

export const poolsRefreshingVar = makeVar<number | undefined>(undefined)

export const assetsModalMarketplaceVar = makeVar(false)

export const selectedAssetVar = makeVar<AssetERC20>({
  id: '1',
  name: 'Nftfy',
  symbol: 'NFTFY',
  address: '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
  balance: '0',
  decimals: 18,
  imageUrl: ''
})

export interface MarketplaceFilter {
  type: MarketplaceItemFilter
  sortingDirection: OrderDirection
  sortingField: MarketplaceItemOrderBy
}

export enum OrderBookType {
  buy = 'buy',
  sell = 'sell',
  delete = 'delete',
  create = 'create',
  update = 'update'
}

export const marketplaceFiltersVar = makeVar<MarketplaceFilter>({
  type: MarketplaceItemFilter.ALL,
  sortingDirection: OrderDirection.desc,
  sortingField: MarketplaceItemOrderBy.timestamp
})

export const updaterOrderModalVar = makeVar<UpdaterOrderModal | undefined>(undefined)

export const orderBookLastOrderVar = makeVar<{ timestamp: number; type: OrderBookType } | undefined>(undefined)
