import { gql } from '@apollo/client'
import { MarketplaceItem, MarketplaceItemFilter, MarketplaceItemOrderBy } from '@appTypes/MarketplaceTypes'
import { OrderDirection } from '@appTypes/UtilTypes'

export interface MarketplaceItemsQueryVars {
  chainId: number
  filterBy: MarketplaceItemFilter
  limit: number
  offset: number
  orderBy: MarketplaceItemOrderBy
  orderDirection: OrderDirection
}

export interface MarketplaceItemsQueryData {
  marketplaceItems: MarketplaceItem[]
}

export const MARKETPLACE_ITEMS = gql`
  query MarketplaceItems(
    $chainId: Int!
    $filterBy: MarketplaceItemFilter!
    $limit: Int!
    $offset: Int!
    $orderBy: MarketplaceItemOrderBy!
    $orderDirection: OrderDirection!
  ) {
    marketplaceItems(
      chainId: $chainId
      filterBy: $filterBy
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      chainId
      type
      status
      exitPrice
      usersCount
      name
      metadata {
        description
        image
        animationType
        animation_url
        asset_contract {
          name
          schema_name
        }
        attributes {
          value
          trait_type
        }
        author
        discord
        imageFull
        instagram
        name
        owner
        properties {
          total_supply {
            type
            description
          }
          preview_media_file_type2 {
            type
            description
          }
          preview_media_file_type {
            type
            description
          }
          preview_media_file2_type {
            type
            description
          }
          preview_media_file2 {
            type
            description
          }
          preview_media_file {
            type
            description
          }
          name {
            type
            description
          }
          description {
            type
            description
          }
          created_at {
            type
            description
          }
        }
        social_media
        telegram
        totalSupply
        twitter
        web_site_url
      }
      id
      target {
        tokenURI
        tokenId
        boxItemCount
        id
        collection {
          name
          id
        }
      }
      fraction {
        totalSupply
        exitPrice
        count
      }
      decimals
      cutoff
      released
      nftCount
      paymentToken {
        symbol
        name
        id
        decimals
      }
      bids {
        bidder
        id
        reservePrice
        timestamp
        txId
      }
      seller
      minimumDuration
      symbol
      timestamp
      totalSupply
      vaultBalance
      wrapper
    }
  }
`
