import { Empty } from 'antd'
import React from 'react'
import styled from 'styled-components'

export default function EmptyState() {
  return (
    <Container>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </Container>
  )
}

const { Container } = {
  Container: styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: ${props => props.theme.margin.large};

    div {
      color: ${props => props.theme.colors.black} !important;
    }

    @media (min-width: ${props => props.theme.viewport.tablet}) {
      margin-bottom: ${props => props.theme.margin.medium};
    }

    @media (min-width: ${props => props.theme.viewport.desktop}) {
      margin-bottom: ${props => props.theme.margin.large};
    }
  `
}
