import { MarketplaceItem, MarketplaceItemStatusEnum, MarketplaceItemTypeEnum } from '@appTypes/MarketplaceTypes'
import CardBase from '@components/design-system/CardBase'
import Icon, { CardTypes, SaleTypesIcons } from '@components/shared/Icon'
import { chainConfig } from '@config'
import { useMarketplaceRoute } from '@hooks/RouteHooks'
import { formatToLocaleString, networkNameById } from '@services/UtilService'
import BigNumber from 'bignumber.js'
import { now } from 'moment'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CountdownFooterCard } from './tags/CountdownFooterCard'

export interface MarketplaceCardProps {
  erc20: MarketplaceItem
}

interface PriceAndTitleNft {
  title: string
  value: string
  amount: string
  symbol: string
  statusBar: string
  type: string
  icon: SaleTypesIcons | CardTypes
}

export default function MarketplaceCard({ erc20 }: MarketplaceCardProps) {
  const [nftPriceType, setNftPriceType] = useState<PriceAndTitleNft | undefined>(undefined)
  const config = chainConfig(erc20.chainId)
  const route = useMarketplaceRoute(erc20)
  const calculatePercent = !erc20.exitPrice ? 0 : new BigNumber(erc20.exitPrice).dividedBy(erc20.exitPrice).multipliedBy(100).toNumber()

  useEffect(() => {
    const getSymbol = (): string => {
      return erc20.paymentToken.symbol === null ? config?.networkTokenSymbol.toUpperCase() || '' : erc20.paymentToken.symbol.toUpperCase()
    }

    const getPrice = (price: string | null): string => {
      if (!price) {
        return 'Not available'
      }
      return formatToLocaleString(price.replaceAll(',', ''), 2)
    }
    // Need to add Accumulated FUnds, Target Price and Starting Auction
    const getTitle = (): PriceAndTitleNft => {
      if (erc20.type === MarketplaceItemTypeEnum.AUCTION && erc20.status === MarketplaceItemStatusEnum.OFFER) {
        return {
          title: 'Reserve Price',
          value: getPrice(erc20.exitPrice),
          amount: getPrice(erc20.exitPrice),
          symbol: getSymbol(),
          statusBar: 'Place a bid',
          type: 'placeBid',
          icon: CardTypes.placeBid
        }
      }

      if (erc20?.type === MarketplaceItemTypeEnum.AUCTION && erc20.cutoff && now() / 1000 < erc20.cutoff) {
        return {
          title: 'Highest Bid',
          value: `${erc20?.exitPrice}` ?? '',
          amount: `${erc20?.exitPrice}` ?? '',
          symbol: getSymbol(),
          statusBar: 'Live Auction',
          type: 'liveAuction',
          icon: SaleTypesIcons.liveAuction
        }
      }

      if (
        erc20.status === MarketplaceItemStatusEnum.AUCTION_OR_SOLD ||
        erc20.status === MarketplaceItemStatusEnum.ENDING ||
        erc20.status === MarketplaceItemStatusEnum.SOLD ||
        (erc20.status === MarketplaceItemStatusEnum.ENDED && erc20.cutoff && now() / 1000 > erc20.cutoff)
      ) {
        return {
          title: 'Sold for',
          value: getPrice(erc20.exitPrice),
          amount: getPrice(erc20.exitPrice),
          symbol: getSymbol(),
          statusBar: 'NFT Sold',
          type: 'recentlySold',
          icon: SaleTypesIcons.recentlySold
        }
      }

      return {
        title: 'Price',
        value: getPrice(erc20.exitPrice),
        amount: getPrice(erc20.exitPrice),
        symbol: getSymbol(),
        statusBar: 'Buy Now',
        type: 'buyNow',
        icon: SaleTypesIcons.buyNow
      }
    }
    setNftPriceType(getTitle())
  }, [erc20, config?.networkTokenSymbol, calculatePercent])

  return (
    <CardBase
      totalMembers={erc20.usersCount ?? 1}
      totalNfts={erc20?.target.boxItemCount || 1}
      network={erc20.chainId}
      imageUrl={String(erc20.metadata?.image)}
      urlRedirect={route}
    >
      <Footer>
        <Info>
          <h4>{`${erc20?.metadata?.name || `${erc20?.target.collection.name} #${erc20?.target.tokenId}`} `}</h4>
          <div>
            <h2>{nftPriceType?.title}</h2>
            <Icon name={networkNameById(erc20.chainId)} size={12} />
            <div>
              <span>{nftPriceType?.value}</span>
              <small>{nftPriceType?.symbol}</small>
            </div>
          </div>
        </Info>
        {nftPriceType && (
          <StatusBar>
            <div>
              <Icon name={nftPriceType?.icon} size={18} />
              <span>{nftPriceType?.statusBar}</span>
            </div>
            <div>
              {nftPriceType.type === 'liveAuction' && (
                <Countdown>
                  <CountdownFooterCard cutoff={Number(erc20.cutoff)} />
                </Countdown>
              )}
            </div>
          </StatusBar>
        )}
      </Footer>
    </CardBase>
  )
}

const { Footer, Info, StatusBar, Countdown } = {
  Footer: styled.div`
    background: ${props => props.theme.colors.gray[1]};
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  `,
  Info: styled.div`
    padding: 8px 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 28px 24px;

    > h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: normal;
      color: ${props => props.theme.colors.black};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    > div {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 8px;
      > h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 133%;
        color: ${props => props.theme.colors.black};
      }
      > div {
        display: flex;
        gap: 4px;
        > span {
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          color: ${props => props.theme.colors.black};
          max-width: 120px;
          width: auto;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 100%;
          display: block;
        }

        > small {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 133%;
          color: ${props => props.theme.colors.black};
        }
      }
    }
  `,
  StatusBar: styled.div`
    display: grid;
    grid-template-columns: 1fr 120px;
    gap: 8px;
    align-items: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-left: 16px;
    padding-right: 16px;
    height: 56px;
    background: ${props => props.theme.colors.gray[0]};

    > div:nth-child(1) {
      display: grid;
      align-items: center;
      grid-template-columns: 14px 1fr;
      gap: 8px;
      > span {
        font-size: 14px;
        line-height: 133%;
        font-weight: 400;
        font-style: normal;
        color: ${props => props.theme.colors.black};
      }
    }
  `,
  Countdown: styled.div`
    > span {
      font-weight: 500;
      font-size: 14px;
      color: ${props => props.theme.colors.black};
    }
  `
}
