import { getErc20Balance } from '@services/WalletService'
import { useEffect, useState } from 'react'
import { NetworksId } from '../config'
import { useLocalStorage } from './LocalStorageHooks'

export function useBalance(account: string | undefined, paymentCurrencyAddress: string, paymentCurrentDecimals: number, chainId: number) {
  const [balance, setBalance] = useState('0')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getBalanceNetworkToken() {
      setLoading(true)
      const accountBalance = await getErc20Balance(String(account), paymentCurrencyAddress, paymentCurrentDecimals, chainId)
      setBalance(accountBalance.toString())
      setLoading(false)
    }
    account && getBalanceNetworkToken()
  }, [account, chainId, paymentCurrencyAddress, paymentCurrentDecimals])

  return { balance, loading }
}
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined
    height: number | undefined
  }>({
    width: undefined,
    height: undefined
  })
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

export const useIsTestnet = () => {
  const { getItem } = useLocalStorage()

  const network = Number(getItem('network'))

  if (network === NetworksId.goerli || network === NetworksId.mumbai || network === NetworksId.binance_testnet) {
    return true
  }

  return false
}
