import React from 'react'

export interface Paged<T> {
  page: number
  per_page: number
  pre_page: number | null
  next_page: number | null
  total: number
  total_pages: number
  data: T
}

export interface Erc721Attribute {
  trait_type: string
  value: string
}

export interface Erc721Properties {
  name: {
    type: string
    description: string
  }
  created_at: {
    type: string
    description: string
  }
  description?: {
    type: string
    description: string
  }
  total_supply?: {
    description: string
  }
  preview_media_file?: {
    type: string
    description: string
  }
  preview_media_file_type?: {
    type: string
    description: string
  }
  preview_media_file2?: {
    type: string
    description: string
  }
  preview_media_file2_type?: {
    type: string
    description: string
  }
  preview_media_file_type2?: {
    type: string
    description: string
  }
}

export enum ProductType {
  'crowdpad' = 'crowdpad',
  'marketplace' = 'marketplace'
}

export interface Metadata {
  name?: string
  image?: string
  imageFull?: string
  description?: string
  animationType?: string
  animation_url?: string
  author?: string
  web_site_url?: string
  social_media?: string
  twitter?: string
  telegram?: string
  discord?: string
  instagram?: string
  totalSupply?: string
  attributes?: Erc721Attribute[] | Record<string, unknown>
  properties?: Erc721Properties
  asset_contract?: {
    name: string
    schema_name: string
  }
}

export enum OrderDirection {
  asc = 'asc',
  desc = 'desc'
}
export interface CommentErc720 {
  id: string
  comment: string
  chainId: string
  marketplaceItemId: string
  walletId: string
  createdAt: string
  updatedAt: string
}

export enum GenericModalTypes {
  success = 'success',
  error = 'error',
  warning = 'warning',
  confirm = 'confirm'
}

export interface GenericModalConfirmation {
  content: object | number | string
}

export interface GenericModal {
  type: GenericModalTypes
  message?: string
  icon?: React.ReactNode
  title?: string
  showClose?: boolean
  closeText?: string
  link?: string
  linkText?: string
  confirmText?: string
  confirmAsLink?: boolean
  showIcon?: boolean
  boxBodyClass?: string
  body?: React.ReactNode
  keyboard?: boolean
  data?: GenericModalConfirmation
}
